import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonMenu, TabbedMenu, StatusChip, Card, ButtonIcon, IconData, LoadingAnimation, Link } from 'app/components';
import SummaryView from './SummaryView';
import HistoryCard from './HistoryCard';
import OrderNotes from './OrderNotes';
import CustomerDetails from './CustomerDetails';
import ShippingAddress from './ShippingAddress';
import PaymentInformation from './PaymentInformation';
import OrderMessageCard from './OrderMessageCard';
import HistoryAndNotesView from './HistoryAndNotesView';
import CancelOrderModal from './SummaryView/Modals/CancelOrderModal';
import { orderDetailsSelector, orderDetailsLoadingSelector, orderDetailsErrorsSelector } from 'app/store/selectors/order';
import { shipmentsLoadingSelector } from 'app/store/selectors/shipment';
import { latestHistoryLoadingSelector } from 'app/store/selectors/history';
import { getOrderDetails, reset as resetOrderData } from 'app/store/actions/order';
import { reset as resetMerchantData } from 'app/store/actions/merchant';
import { getLatestHistory, reset as resetHistoryData } from 'app/store/actions/history';
import { getNotes, reset as resetNotesData } from 'app/store/actions/note';
import { getShipmentsByOrderId } from 'app/store/actions/shipment';
import { ArrowLeft, Upc, Calendar2Event, Truck } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from 'app/utils';
import { usePermission } from 'app/permissions';
import moment from 'moment';
import './index.scss';

const tabs = [
  {
    path: 'summary',
    label: 'Order Details',
    content: SummaryView,
  },
  {
    path: 'history',
    label: 'History & Notes',
    content: HistoryAndNotesView,
  },
];

const OrderDetails = () => {
  const [cancelOrderModal, showCancelOrderModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const orderDetails = useSelector(orderDetailsSelector);
  const orderDetailsLoading = useSelector(orderDetailsLoadingSelector);
  const orderDetailsErrors = useSelector(orderDetailsErrorsSelector);
  const shipmentsLoading = useSelector(shipmentsLoadingSelector);
  const historyLoading = useSelector(latestHistoryLoadingSelector);

  // get user permissions
  const canUpdateOrder = usePermission('order', 'update');
  const canSearchMerchants = usePermission('merchant', 'get');

  // if there are order details errors, find the imte-details tab and splice it out
  if (orderDetailsErrors) {
    tabs.find((tab, index) => {
      if (tab.path === 'item-details') {
        tabs.splice(index, 1);
        return true;
      }
    })
  }

  useEffect(() => {
    if (!orderDetails || orderDetails.id !== orderId) {
      dispatch(getOrderDetails(orderId));
      dispatch(getShipmentsByOrderId(orderId));
      dispatch(getLatestHistory({ parentType: 'Order', parentId: orderId, entityType: '', page: 1, pageSize: 5, sortAscending: 'desc' }));
      dispatch(getNotes({ entityId: orderId, entityType: 'Order' }));
    }

    return () => {
      dispatch(resetOrderData());
      dispatch(resetMerchantData());
      dispatch(resetHistoryData());
      dispatch(resetNotesData());
    };
  }, [dispatch]);

  const orderOptions = [
    // { value: 'Duplicate Order', label: 'Duplicate Order', onClick: () => { console.log("Not implemented yet") }},
    ...(canUpdateOrder ? [{ value: 'Refund', label: 'Refund', onClick: () => { navigate('refund/items') } }] : []),
    // { value: 'Reprint', label: 'Reprint', onClick: () => { console.log("Not implemented yet") }},
    ...(canUpdateOrder ? [{ value: 'Cancel Order', label: 'Cancel Order', destructive: true, onClick: () => { showCancelOrderModal(true) } }] : [])
  ];

  return (
    <div className="order-details">
      {(orderDetailsLoading || shipmentsLoading || historyLoading) && <LoadingAnimation />}
      {(orderDetails || orderDetailsErrors) && (
        <>
          <div className="left-panel">
            <Card className="order-details-header">
              {orderDetails?.merchantName && (
                <div className="merchant-name">
                  {orderDetails?.merchantName && orderDetails?.merchantId && canSearchMerchants ? (
                    <Link
                      label={orderDetails.merchantName}
                      url={`/admin/merchants/${orderDetails.merchantId}`}
                      onClick={() => {
                        navigate(`/admin/merchants/${orderDetails.merchantId}`);
                      }}
                    />
                  ) : (orderDetails?.merchantName || '\u00A0')}
                </div>
              )}
              <div className="order-num-container">
                <div className="order-num-group">
                  <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/orders')} />
                  <div className="order-num">
                    Order #{orderDetails?.shortId || orderId}
                  </div>
                  {orderDetails && <StatusChip type="order" status={orderDetails.status} />}
                </div>
                {!orderDetailsErrors && orderOptions.length > 0 && (
                  <ButtonMenu
                    label="Order Options"
                    variant="primary"
                    size="small"
                    options={orderOptions}
                    width={145}
                  />
                )}
              </div>
              <div className="extra-details-container">
                {orderDetails?.externalId && (
                  <IconData label={orderDetails.externalId} icon={<Upc />} />
                )}
                {orderDetails?.createdAt && (
                  <IconData label={moment(orderDetails.createdAt).local().format('MMM DD, YYYY h:mm a')} icon={<Calendar2Event />} />
                )}
                {orderDetails?.customerShippingMethod && (
                  <IconData label={capitalizeFirstLetter(orderDetails.customerShippingMethod)} icon={<Truck />} />
                )}
              </div>
              <OrderMessageCard orderDetails={orderDetails} />
            </Card>
            <TabbedMenu tabs={tabs} orderId={orderId} />
            {cancelOrderModal && (
              <CancelOrderModal
                orderDetails={orderDetails}
                showCancelOrderModal={showCancelOrderModal}
              />
            )}
          </div>
          <div className="right-panel">
            <HistoryCard />
            <OrderNotes />
            {orderDetails && <CustomerDetails orderId={orderId} shippingAddress={orderDetails.shippingAddress} />}
            {orderDetails && <ShippingAddress orderId={orderId} shippingAddress={orderDetails.shippingAddress} />}
            {orderDetails?.customerPaymentMethods?.length > 0 && <PaymentInformation paymentMethods={orderDetails.customerPaymentMethods} />}
          </div>
        </>
      )}
    </div>
  )
}

export default OrderDetails;