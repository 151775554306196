import React from 'react';
import { Card } from 'app/components';
import './index.scss';

const PaymentInformation = (props) => {
  const { paymentMethods } = props;

  return (
    <Card className="payment-information">
      <Card.Header>
        Payment Information
      </Card.Header>
      <Card.Body>
        {paymentMethods.map((paymentMethod, index) => {
          const billingInfo = paymentMethod.billingInfo;

          const address = billingInfo ? [
            billingInfo.address?.line1,
            billingInfo.address?.line2,
            billingInfo.address?.city,
            billingInfo.address?.state ? `${billingInfo.address?.state},` : '',
            billingInfo.address?.zip,
            billingInfo.address?.countryCode
          ].filter(Boolean).join(' ') : null;

          return (
            <div key={index} className="payment-method">
              {billingInfo.fullName && <div className="data-value">{billingInfo.fullName}</div>}
              {billingInfo.address && <div className="data-value">{address}</div>}
              {billingInfo.address.phone && <div className="data-value">{billingInfo.address.phone}</div>}
              {paymentMethod.cardLast4 && <div className="data-value">{`********${paymentMethod.cardLast4}`}</div>}
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default PaymentInformation;
